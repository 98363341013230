.wrap {
  position: relative;
  z-index: 0;

  &:hover {
    .hidden {
      @media (--md-up) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.top {
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
  position: absolute;
  left: 20px;
  top: 24px;
  max-width: calc(100% - 102px);
  z-index: 2;
}

.name {
  text-transform: uppercase;
  color: var(--primary-main);

  @media (--md-down) {
    font-size: 13px;
  }
}

.actions {
  position: absolute;
  right: 0;
  display: flex;
  top: 16px;
  padding: 0 7px;
  gap: 7px;
  z-index: 2;
}

.hidden {
  transition: all ease 0.25s;

  @media (--md-up) {
    opacity: 0;
    visibility: hidden;
  }
}

.info {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  padding: 0 20px;
  line-height: 20px;

  .price {
    margin-left: auto;
  }
}

.itemLink {
  display: block;
  color: var(--text-primary);
}

.imgWrap {
  padding-top: 100%;
  position: relative;

  &:hover {
    .regularImg {
      @media (--md-up) {
        opacity: 0;
      }
    }

    .hoverImg {
      display: none;

      @media (--md-up) {
        display: block;
        opacity: 1;
      }
    }
  }

  @media (--md-down) {
    height: 282px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    transition: all ease 0.25s;
    position: absolute;
    inset: 0;
    object-fit: contain;
  }

  .hoverImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 0;
  }
}

.metalsWrap {
  display: flex;
  align-items: center;
  max-width: calc(100% - 100px);
}

.metals {
  display: flex;
  flex-flow: row-reverse wrap;
  gap: 8px;
  padding: 0 7px;
  transform: rotate(180deg);
}

.metalWrap {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}

.metal {
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  padding: 1px;
  width: 100%;
  height: 100%;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.active {
    border-color: #000;
  }
}

.moreBtn {
  margin-left: 2px;
  padding: 0 5px;
  text-transform: uppercase;
  font-size: 10px;

  &:hover {
    background: rgb(80 73 91 / 4%);
  }
}
