.wrap {
  padding: 90px 0;
  background: var(--secondary-light);

  @media (--md-down) {
    padding: 60px 0 50px;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
  }
}

.controls {
  margin-top: 46px;

  @media (--md-up) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  @media (--md-down) {
    margin-top: 22px;
    text-align: center;
  }

  .btnWrap {
    margin-top: 25px;
  }

  .rating {
    display: flex;
    align-items: center;

    @media (--md-down) {
      justify-content: center;
      margin-top: 22px;
    }

    .value {
      font-weight: 500;
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .count {
    text-align: center;
    text-transform: uppercase;
    margin-top: 8px;
  }

  .sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;

    @media (--md-down) {
      display: none;
    }

    .selectWrap {
      width: 170px;
      background: var(--background-paper);
      margin-left: 20px;
    }
  }
}

.list {
  margin-top: 36px;
}

.moreBtnWrap {
  text-align: center;
  padding-top: 68px;
}
