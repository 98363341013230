.categoryBannerWrapper {
  padding-bottom: 45px;

  @media (--sm-down) {
    padding-bottom: 30px;
  }
}

.infoBannerWrap {
  margin-top: -135px;
}
