.wrap {
  margin: 90px 0;

  @media (--md-down) {
    margin: 45px 0;
  }

  h1,
  .description {
    text-align: center;
  }

  .description {
    margin-top: 20px;
    color: var(--primary-main);
  }
}
