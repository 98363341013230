.wrap {
  width: 100%;
  position: relative;
}

.placeholderWrap {
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

.scrollWrap {
  display: none;
  position: absolute;
  inset: 0;

  &.show {
    display: block;
  }
}
