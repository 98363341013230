.appointmentsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media (--lg-down) {
    grid-template-columns: 1fr;
    margin-left: -30px;
    margin-right: -30px;
  }

  .item:nth-child(3) {
    @media (--lg-up) {
      grid-column: span 2;
    }
  }
}
