.wrap {
  @media (--md-up) {
    display: grid;
    grid-template-columns: 1.7fr 0.3fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 48px 10px;
  }

  @media (--md-down) {
    margin: 0 -30px;
  }
}

.titleWrap {
  grid-area: 1 / 1 / 2 / 2;

  @media (--md-down) {
    text-align: center;
  }

  @media (--sm-down) {
    margin-bottom: 23px;
  }
}

.slider {
  grid-area: 2 / 1 / 3 / 3;
}

.controls {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;

  @media (--md-down) {
    justify-content: center;
    margin-top: 30px;
  }
}
