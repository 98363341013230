.widgetWrap {
  width: 680px;
  height: 850px;
  padding: 7px;

  @media (--md-down) {
    width: 83vw;
  }

  @media (--sm-down) {
    width: 100vw;
    height: 100vh;
  }

  > div {
    height: 100%;
  }
}

.close {
  position: absolute;
  left: 0;
  top: 0;

  @media (--sm-up) {
    display: none;
  }
}

.modalRoot .modalPaper {
  background-color: transparent;
  box-shadow: none;

  @media (--sm-down) {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
}
