.wrapper {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0;
  }
}

.contentWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  align-items: center;

  @media (--md-down) {
    display: flex;
    flex-direction: column;
  }
}

.imgWrapper {
  width: 718px;
  height: 1000px;
  margin: 0 auto;
  position: relative;

  @media (--lg-down) {
    width: 500px;
    height: 650px;
  }

  @media (--md-down) {
    width: 100%;
    height: 700px;
  }

  .img {
    object-fit: cover;
  }
}

.textWrapper {
  h2 {
    font-size: 48px;
    max-width: 295px;

    @media (--md-down) {
      font-size: 36px;
    }
  }

  .description {
    max-width: 356px;
    margin-top: 34px;
  }
}
