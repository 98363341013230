.titleSection {
  text-align: center;
  padding: 60px 0 40px;
  color: var(--text-secondary);

  @media (--sm-down) {
    padding: 30px 0 25px;
  }

  h1 {
    color: var(--primary-main);
    margin-bottom: 20px;
    line-height: 1;
    font-size: 42px;

    @media (--sm-down) {
      font-size: 21px;
    }
  }

  .description {
    color: var(--primary-main);
  }
}

.listLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
}
