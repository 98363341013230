.wrap {
  padding-top: 75px;

  @media (--md-down) {
    padding-top: 35px;
  }
}

.listWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (--md-down) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--sm-down) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 35px;
  }
}
