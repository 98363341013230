.btn {
  width: 68px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  transition: all ease 0.25s;
  padding: 12px;

  &:hover {
    color: var(--text-primary);
  }
}
