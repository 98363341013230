.wrap {
  display: flex;
  flex-direction: column;
  gap: 90px;
  padding: 90px 0 45px;

  @media (--sm-down) {
    padding: 60px 0 30px;
    gap: 45px;
  }
}

.item {
  position: relative;

  &.dark::before {
    content: '';
    inset: 0;
    background-color: var(--primary-main);
    position: absolute;
    top: 90px;
    z-index: -1;
  }
}
