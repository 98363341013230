.wrap {
  padding: 0;
  margin: 0 -90px;

  @media (--xxl-down) {
    margin: 0 -30px;
  }
}

.slider {
  background-color: var(--background-default);
  border: 1px solid var(--divider);
  border-right: none;
}

.itemLink {
  border-right: 1px solid var(--divider);
}
