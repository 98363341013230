.wrap {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0 60px;
  }
}

.contentWrap {
  display: flex;

  @media (--lg-down) {
    margin: 0 -30px;
    flex-direction: column-reverse;
  }
}

.textBlock {
  min-width: 737px;
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-light);

  &.fullWidth {
    width: 100%;
  }

  @media (--lg-down) {
    min-width: 100%;
    padding: 61px 30px;
  }

  h2 {
    font-size: 48px;
    max-width: 542px;
    text-align: center;

    @media (--lg-down) {
      font-size: 32px;
    }
  }

  .subtitle {
    margin-top: 10px;
    max-width: 471px;
    text-align: center;

    @media (--lg-down) {
      font-size: 14px;
    }
  }

  .link {
    margin-top: 39px;

    @media (--lg-down) {
      width: 100%;
    }
  }
}

.mediaWrap {
  height: 816px;
  width: 100%;
  position: relative;

  @media (--lg-down) {
    height: 345px;
  }

  .img {
    object-fit: cover;
  }
}
