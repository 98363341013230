.wrap {
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  font-family: var(--font-secondary);
  font-size: 20px;
  margin: 0 24px;
}

.wrap.primary {
  color: var(--primary-contrastText);

  .value {
    color: var(--primary-contrastText);
  }

  .length {
    color: var(--primary-contrastText);
  }
}
