.wrap {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 150px;
  background: var(--secondary-main);
  color: #fff;
}

.logo {
  margin-bottom: 20px;
}
