.wrap {
  position: fixed;
  inset: 0;
  z-index: 5;
  background: var(--secondary-main);
  padding: 50px 0;
  display: flex;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  input {
    height: 46px;
  }
}

.label {
  white-space: nowrap;
  font-weight: 500;
  padding-right: 18px;
}

.options {
  position: relative;
  margin-top: 30px;
  min-height: 50px;

  .item {
    text-transform: uppercase;
    line-height: 40px;
  }
}

.schedule {
  margin-top: auto;
  text-align: center;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .btnRoot {
    margin: 20px;
    background: var((--bright-main));
  }
}

.buttons {
  display: flex;
}
