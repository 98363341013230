.wrap {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0 60px;
  }

  .slide {
    width: 50%;

    @media (--lg-down) {
      width: 100%;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 45px;
  flex-wrap: wrap;

  @media (--md-down) {
    margin-bottom: 30px;
    padding-left: 20px;
  }

  @media (--sm-down) {
    gap: 20px;
  }
}

.btn {
  .longText {
    @media (--sm-down) {
      display: none;
    }
  }

  .shortText {
    display: none;

    @media (--sm-down) {
      display: inline;
    }
  }
}

.designersList {
  display: flex;
  margin-top: 48px;
  gap: 20px;

  @media (--lg-down) {
    flex-direction: column;
  }

  @media (--md-down) {
    margin-left: -30px;
    margin-right: -30px;
  }
}
