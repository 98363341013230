.filterWrap {
  display: flex;
  gap: 33px;
  justify-content: space-between;
  align-items: center;

  @media (--md-down) {
    flex-direction: column;
  }

  .resetRoot {
    white-space: nowrap;
    font-weight: 400;
  }
}

.title {
  font-size: 16px;
  color: var(--grey-500);
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
}

.linkItem {
  font-size: 16px;
  color: var(--text-primary);
  cursor: pointer;

  &.active {
    font-weight: 700;
  }

  &.firsActive {
    &:first-child {
      font-weight: 700;
    }
  }
}
