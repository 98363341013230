.wrapper {
  padding: 45px 0;

  @media (--md-down) {
    padding: 30px 0;
  }
}

.previewWrapper {
  position: relative;

  &::after {
    content: '';
    display: block;
    inset: 0;
    background-color: #332d3b;
    position: absolute;
    translate: 0 -50%;
    z-index: -1;
  }
}
