.wrap {
  .selectRoot {
    fieldset {
      border-color: #dad7cd;
    }
  }

  .select {
    background: var(--background-default);
  }
}

.label {
  font-size: 16px;
  color: var(--primary-main);
  margin-bottom: 14px;
}

.btnWrap {
  margin-top: 33px;
}
