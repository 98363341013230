.wrap {
  padding: 45px 0;

  @media (--md-down) {
    padding: 30px 0;
  }
}

.contentWrap {
  height: 816px;
  position: relative;

  @media (--md-down) {
    height: 616px;
  }
}

.img {
  object-fit: cover;
}

.textBloc {
  text-align: center;
  width: 708px;
  padding: 100px 118px;
  position: absolute;
  top: 50%;
  translate: 0% -50%;
  background-color: var(--background-default);
  z-index: 1;

  @media (--md-down) {
    padding: 40px;
  }

  @media (--sm-down) {
    padding: 20px 10px;
  }

  h2 {
    font-size: 48px;

    @media (--md-down) {
      font-size: 36px;
    }

    @media (--sm-down) {
      font-size: 30px;
    }
  }

  @media (--md-down) {
    width: 100%;
  }

  &.right {
    left: auto;
    right: -1px;
  }

  &.left {
    left: -1px;
    right: auto;
  }
}

.description {
  margin: 10px 0 60px;
}
