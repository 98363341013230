.wrap {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0;
  }

  .slide {
    width: 25%;

    @media (--xl-down) {
      width: 33.333%;
    }

    @media (--lg-down) {
      width: 50%;
    }

    @media (--lg-down) {
      width: 256px;
    }
  }
}

.smDownHide {
  @media (--sm-down) {
    display: none !important;
  }
}

.smDownCenter {
  @media (--sm-down) {
    text-align: center;
    right: 20px;
    max-width: 100%;
  }
}
