.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 40px;
}

.title {
  margin-top: 48px;
  color: var(--primary-main);
}
