.bannerWrap {
  padding-bottom: 45px;

  @media (--sm-down) {
    padding-bottom: 30px;
  }
}

.itemLink {
  background-color: #f7f7f7;
}
