.wrap {
  color: var(--primary-main);

  @media (--md-down) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 4;
    padding-bottom: 30px;
  }

  .callFilterBtnWrap {
    @media (--md-up) {
      display: none;
    }
  }

  .callFilter {
    font-size: 24px;
    background: #7d699c;
    border-radius: 15px;
    padding: 16px;
    position: relative;
    z-index: 2;

    &:hover {
      background: #7d699c;
    }
  }

  .containerRoot {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.additionalFilters {
  @media (--md-up) {
    background: var(--secondary-main);
  }
}

.cell {
  padding: 22px 0;
  height: 100%;
  position: relative;

  @media (--md-down) {
    padding: 6px 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -30px;
    right: -30px;
    border-bottom: 1px solid var(--grey-300);
  }

  @media (--md-up) {
    display: flex;
  }

  .label {
    text-transform: uppercase;
    font-weight: 500;
    flex-shrink: 0;
    text-align: left;

    @media (--md-up) {
      padding: 7px 10px 7px 0;
      cursor: default;
      width: 90px;
    }

    @media (--md-down) {
      padding: 9px 0;
    }

    .chevron {
      font-size: 20px;
      transition: all ease 0.25s;
      display: flex;

      @media (--md-up) {
        display: none;
      }

      &.open {
        transform: rotate(180deg);
      }
    }

    @media (--md-down) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .control {
    flex-grow: 1;
  }

  .controlInner {
    @media (--md-down) {
      padding-bottom: 20px;
    }
  }
}

.backdrop {
  position: fixed;
  inset: 0;
  background: rgb(var(--primary-main-rgb) / 30%);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;

  @media (--md-up) {
    display: none;
  }

  .open & {
    @media (--md-down) {
      opacity: 1;
      visibility: visible;
    }
  }
}

.filter {
  @media (--md-down) {
    position: absolute;
    bottom: -80vh;
    height: 80vh;
    background: var(--background-paper);
    border-radius: 30px 30px 0 0;
    transition: bottom ease 0.3s;
    overflow-y: auto;
    padding-bottom: 80px;
  }

  &.open {
    bottom: 0;
  }
}

.buttons {
  padding: 20px 0;
}

.hideMdDown {
  @media (--md-down) {
    display: none !important;
  }
}
