.wrap {
  margin-top: 54px;
}

.title {
  font-size: 18px;
  color: var(--primary-main);
  padding-bottom: 26px;
  margin-bottom: 17px;
  border-bottom: 1px solid var(--grey-500);
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.address {
  font-size: 13px;
  color: var(--primary-main);
}

.map {
  display: inline-block;

  .link {
    color: var(--text-primary);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      border-bottom: 1px solid var(--text-primary);
    }
  }
}
