.wrap {
  width: 100%;
  height: 100%;
  padding: 117px 150px;
  background-color: var(--secondary-main);
  position: relative;

  @media (--xl-down) {
    padding: 100px 120px;
  }

  @media (--lg-down) {
    padding: 50px 70px;
  }

  @media (--sm-down) {
    padding: 25px 35px;
  }
}

.bgWrapper {
  height: 100%;
  padding-left: 635px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  @media (--lg-down) {
    display: none;
  }

  img {
    object-fit: cover;
  }
}

.content {
  display: flex;
  gap: 64px;
  position: relative;
  z-index: 2;

  @media (--lg-down) {
    gap: 40px;
  }

  @media (--sm-down) {
    flex-direction: column;
    align-items: center;
  }
}

.iconWrapper {
  display: flex;
  min-width: 84px;
  width: 84px;
}

.subtitle {
  max-width: 447px;
  margin-top: 16px;
  color: var(--primary-main);
}

.childrenWrapper {
  margin: 54px 0;
}
