.wrap {
  border: 1px solid var(--divider);
  position: relative;
  padding: 18px 22px;

  legend {
    margin: 0 auto;
    padding: 0 35px;
  }
}
