.wrap {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0;
  }
}

.row {
  @media (--md-up) {
    display: flex;
  }

  @media (--md-down) {
    margin: 0 -30px;
  }
}

.col {
  color: var(--bright-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: var(--bright-contrastText);
  padding: 0 90px 175px;
  position: relative;

  @media (--md-up) {
    width: 50%;
    border: 1px solid var(--bright-main);
  }

  @media (--md-down) {
    padding: 0 30px 45px;
  }

  &:first-child {
    background: var(--primary-main);
  }

  .image {
    max-width: 634px;
    max-height: 100%;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}

.title {
  margin-top: 25px;
  font-family: var(--font-secondary);
  line-height: 1.2;
  font-size: 48px;

  @media (--md-down) {
    font-size: 24px;
  }
}

.btnWrap {
  @media (--md-up) {
    position: absolute;
    bottom: 84px;
    right: 0;
    left: 0;
    text-align: center;
  }

  @media (--md-down) {
    width: 100%;
    margin-top: 22px;
  }
}

.imgWrap {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  .inner {
    position: relative;
    padding-top: 90%;
  }

  img {
    object-fit: cover;
  }
}
