.wrap {
  padding-bottom: 90px;

  @media (--sm-down) {
    padding-bottom: 45px;
  }
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgWrap {
  width: 100%;
  padding-top: 33%;
  position: relative;
  overflow: hidden;

  @media (--sm-down) {
    padding-top: 63%;
  }

  img {
    object-fit: cover;
  }
}

.title {
  margin-top: 50px;
}

.description {
  margin-top: 25px;
  max-width: 855px;
  text-align: center;
}

.children {
  width: 100%;
  margin-top: 20px;

  @media (--md-down) {
    margin-top: 40px;
  }
}

.wrap .link {
  margin-top: 60px;

  @media (--md-down) {
    margin-top: 30px;
  }
}

.wrap.dark {
  .title {
    color: var(--primary-contrastText);
  }

  .description {
    color: var(--primary-contrastText);
  }
}
