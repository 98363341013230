.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 64px;
  justify-content: center;
  padding: 100px;
  background-color: var(--secondary-main);
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.icon {
  width: 80px;
}

.title {
  margin-bottom: 16px;
}

.description {
  max-width: 413px;
  margin-bottom: 32px;
  color: var(--primary-main);
}

.button {
  margin-top: auto;
}
