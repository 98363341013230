.contentWrap {
  padding-bottom: 94px;
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 20px;
  display: block;
  color: var(--text-primary);
  position: relative;
  background: var(--background-secondary);
  transition: background ease 0.5s;

  &:hover {
    background: var(--secondary-main);

    img {
      transform: scale(1.5);
    }
  }
}

.name {
  padding: 0 30px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  translate: 0 50%;

  @media (--sm-down) {
    padding: 0 15px;
  }
}

.imgWrap {
  height: 330px;
  position: relative;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    transition: all ease 0.5s;
    object-fit: cover;
  }
}
