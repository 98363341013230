.wrapper {
  padding: 40px 0;
  background-color: var(--primary-main);

  @media (--sm-down) {
    padding: 18px;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;

  h2 {
    color: var(--background-default);

    @media (--sm-down) {
      width: 100%;
      font-size: 20px;
      text-align: center;
    }
  }

  .smDown {
    @media (--sm-down) {
      display: none;
    }
  }
}
