.wrap {
  min-height: 600px;
  padding: 40px 0;
  display: flex;
  align-items: center;
  color: var(--primary-contrastText);
  background-color: var(--primary-main);
  position: relative;

  @media (--md-down) {
    min-height: 300px;
  }

  &.small {
    min-height: 350px;
  }
}

.content {
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
  position: relative;

  h1 {
    margin-bottom: 34px;
  }
}

.bg {
  object-fit: cover;
  opacity: 0.3;
}
