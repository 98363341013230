.wrap {
  padding-bottom: 150px;
}

.actions {
  padding-top: 50px;
}

.list {
  position: relative;

  .loadTrigger {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
