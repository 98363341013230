.wrap {
  display: grid;
  grid-template-columns: 680px auto;

  @media (--md-down) {
    grid-template-columns: 1fr;
  }
}

.textWrap {
  color: var(--background-default);
  background-color: var(--secondary-contrastText);
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 138px 90px;

  h2 {
    align-self: start;
  }

  @media (--md-down) {
    padding: 30px 20px;
  }

  @media (--md-down) {
    padding: 20px 10px;
  }
}

.imageWrap {
  min-height: 500px;
  position: relative;

  .img {
    object-fit: cover;
  }
}
