.inputWrap {
  background: var(--background-default);
}

.wrap {
  padding: 15px 0;
  border-top: 1px solid var(--divider);
  border-bottom: 1px solid var(--divider);

  .btn {
    height: 100%;
  }
}
