.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  padding: 15px 30px;
  color: var(--primary-main);

  &:not(:first-child) {
    border-top: 1px solid var(--divider);
  }

  &.compact {
    padding: 0;
    border-top: none;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
}

.totalPrice {
  font-size: 18px;
}

.nameWrap {
  display: flex;
  align-items: center;
}

.name {
  font-size: 18px;
  font-family: var(--font-secondary);
}

.imgWrap {
  width: 150px;
  height: 150px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .compact & {
    width: 100px;
    height: 100px;
  }
}

.info {
  flex-grow: 1;
}

.link {
  color: var(--primary-main);
  text-decoration: underline;
}

.topInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
