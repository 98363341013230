.wrapper {
  margin-left: auto;
}

.collage {
  justify-content: end;
}

.textBlock {
  margin: 34px 0 0;
}

.smallImg {
  margin-right: -140px;

  @media (--lg-down) {
    margin-right: 0;
  }
}

.description {
  max-width: 600px;
}
