.empty {
  padding: 150px 0 300px;
  font-size: 28px;
  text-align: center;
  font-family: var(--font-secondary);
}

.list {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(4, 1fr);

  @media (--lg-down) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--md-down) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--sm-down) {
    margin: 0 -30px;
    grid-template-columns: 1fr;
  }
}

.item {
  box-shadow: 0 0 0 1px var(--divider);
}

.promotion {
  @media (--sm-up) {
    grid-column: span 2;
  }
}
