.wrap {
  padding: 30px 0;
  position: relative;
  z-index: 0;

  @media (--lg-down) {
    text-align: center;
  }
}

.pagination {
  .actions {
    @media (--md-down) {
      display: none;
    }
  }

  .displayedRows {
    @media (--md-down) {
      display: none;
    }
  }

  .spacer {
    @media (--md-down) {
      display: none;
    }
  }

  .select {
    @media (--md-down) {
      margin-right: 0;
    }
  }

  .toolbar {
    @media (--md-down) {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

.row {
  position: relative;
  z-index: 3;
}
