.wrap {
  display: flex;
  flex-direction: column;
  background-color: var(--background-default);
  border-right: 1px solid var(--divider);
  height: 100%;

  .btnRoot {
    @media (--sm-down) {
      font-size: 16px;
    }
  }
}

.head {
  display: flex;
  flex-direction: column;
  padding: 18px;
  height: 100%;

  button {
    margin-top: auto;
  }
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 324px;
  overflow: hidden;
  border: 1px solid var(--divider);
  position: relative;

  @media (--sm-down) {
    height: 248px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
}

.title {
  color: var(--text-primary);
  margin: 18px 0 36px;
  text-align: center;

  @media (--sm-down) {
    font-size: 14px;
    margin: 20px 0;
  }
}

.list {
  padding-bottom: 18px;

  @media (--sm-down) {
    padding-bottom: 0;
  }
}

.listItem {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
  color: var(--grey-500);
  padding: 24px 40px;

  &:nth-child(2n + 1) {
    background-color: #3f384a1a;
  }

  &.price {
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;
    color: var(--primary-main);
  }

  @media (--sm-down) {
    font-size: 14px;
    line-height: 20px;
    padding: 18px 40px;

    &.price {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.actionButtons {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
