.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  background-color: var(--background-secondary);
  margin-top: 45px;

  @media (--sm-down) {
    padding: 30px;
    margin-top: 30px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 0 20px;

  @media (--xl-down) {
    width: 25%;
  }

  @media (--xl-down) {
    width: 35%;
  }

  @media (--sm-down) {
    width: 50%;
  }
}

.img {
  display: flex;
  position: relative;
  height: 60px;
  width: 100%;

  @media (--sm-down) {
    height: 30px;
  }

  img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}
