.wrap {
  z-index: 1;
  position: fixed;
  inset: 127px 0 0;
}

.noScroll {
  overflow: hidden;
}

.backdrop {
  position: absolute;
  inset: 0;
  background: rgb(var(--primary-main-rgb) / 80%);
}

.content {
  background: var(--background-paper);
  position: absolute;
  padding: 20px 0;
  width: 470px;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  @media (--sm-down) {
    width: 100%;
  }

  .close {
    position: absolute;
    right: 30px;
    top: 10px;
  }
}

.titleWrap {
  text-align: center;
  padding: 0 30px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--divider);

  h2 {
    color: var(--primary-main);
  }

  .subtitle {
    color: var(--text-secondary);
    margin-top: 15px;
  }
}

.bottomSection {
  padding: 0 30px;
  border-top: 1px solid var(--divider);

  .price {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: var(--primary-main);
    padding: 15px 0;
  }

  .value {
    font-weight: 500;
  }

  .label {
    font-family: var(--font-secondary);
  }
}

.list {
  overflow: hidden;
}

.links {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .item {
    width: 50%;
    padding: 15px 15px 0;
    color: var(--primary-main);
  }

  .link {
    color: var(--primary-main);
    text-decoration: underline;
  }
}
