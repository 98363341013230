.wrap {
  .dividerRoot {
    margin-top: -1px;
    position: relative;
    z-index: 1;
  }

  .topReviews {
    padding-bottom: 45px;
  }

  .bottomReviews {
    padding-top: 45px;
  }
}
