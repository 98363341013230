.imgWrap {
  position: relative;
  padding-top: 80%;
  overflow: hidden;
  width: 100%;

  &:hover {
    .img {
      scale: 1.1;
    }
  }

  .img {
    object-fit: cover;
    transition: 0.35s;
  }
}

.titleWrap {
  margin: 41px 0 22px;

  h2 {
    font-size: 20px;
    color: var(--text-primary);

    @media (--lg-down) {
      font-size: 20px;
    }
  }
}

.description {
  color: var(--text-primary);
  margin-top: auto;
}
