.caratSliderWrap {
  padding: 60px 30px 0;

  @media (--md-down) {
    padding: 30px 20px 0;
  }
}

.sliderWrap {
  padding: 40px 30px 0;

  @media (--md-down) {
    padding: 20px 20px 0;
  }
}

.caratSliderWrap,
.sliderWrap {
  .sliderRoot {
    cursor: not-allowed;
  }

  .railRoot {
    height: 3px;
    background-color: var(--secondary-main);
  }

  .markLabelRoot {
    color: var(--primary-main);
  }

  .markRoot {
    background-color: var(--secondary-main);
  }
}

.label {
  color: var(--primary-main);
  white-space: nowrap;
  text-align: center;

  @media (--md-down) {
    font-size: 9px;
  }
}

.loaderWrap {
  display: flex;
  justify-content: center;
  padding: 40px 30px 0;
}

.explanation {
  padding: 27px;
  border: 2px solid var(--secondary-main);
  margin-top: 25px;
  container: explanation / inline-size;

  @media (--sm-down) {
    padding: 10px;
  }
}

@container explanation (width < 400px) {
  .explanationCut {
    flex-direction: column;
  }
}

.imgWrap {
  width: 162px;
  min-width: 162px;
  height: 130px;
  position: relative;
}

.explanationCut {
  display: flex;
  align-items: center;
  gap: 44px;
}

.title {
  font-weight: 500;
  text-transform: uppercase;
}

.description {
  margin-top: 10px;
  color: var(--secondary-contrastText);
}

.clarityItem {
  position: relative;

  &.separator::after {
    content: '';
    border-right: 1px solid var(--secondary-main);
    height: 115%;
    top: 60%;
    right: -15px;
    transform: translate(0, -50%);
    position: absolute;

    @media (--sm-down) {
      right: -8px;
    }
  }
}

.clarityImageTitle {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  margin-bottom: 14px;
  position: absolute;

  @media (--sm-down) {
    font-size: 8px;
    margin-bottom: 8px;
  }
}

.clarityImageWrap {
  margin-top: 28px;
  padding-top: 100%;
  position: relative;

  @media (--sm-down) {
    margin-top: 18px;
  }
}

.explanationClarity {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 30px;

  @media (--sm-down) {
    column-gap: 16px;
  }
}

.firstText,
.secondText {
  font-weight: 500;
  text-align: center;
  margin-top: 19px;

  @media (--sm-down) {
    font-size: 10px;
    margin-top: 8px;
  }
}

.firstText {
  grid-column: span 3;
}

.secondText {
  grid-column: span 2;
}
