.wrap {
  color: var(--bright-main);

  @media (--lg-up) {
    display: grid;
    grid-template-columns: 72% 28%;
  }
}

.col {
  position: relative;

  @media (--md-up) {
    min-height: 818px;
  }

  img {
    object-fit: cover;
  }
}

.primary {
  @media (--lg-up) {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--bright-main);
  }

  .content {
    @media (--lg-down) {
      background: var(--accent-main);
      color: var(--accent-contrastText);
      text-align: center;
    }
  }

  h1 {
    margin-bottom: 10px;

    @media (--sm-down) {
      margin-bottom: 5px;
    }
  }

  .bg {
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (--lg-up) {
      inset: 0;
      position: absolute;
    }

    @media (--lg-down) {
      padding-top: 59%;
    }
  }

  .buttons {
    @media (--sm-up) {
      margin-top: 25px;
    }

    .btn {
      margin-top: 20px;

      @media (--lg-down) {
        width: 100%;
      }

      @media (--sm-down) {
        font-size: 13px;
      }
    }
  }
}

.secondary {
  display: flex;
  align-items: flex-end;
  text-align: center;

  @media (--lg-down) {
    display: none;
  }

  h3 {
    margin-bottom: 25px;
  }

  .content {
    @media (--xl-down) {
      padding: 40px;
    }
  }
}

.content {
  position: relative;
  padding: 40px 90px;

  @media (--lg-up) {
    max-width: 660px;
  }

  @media (--sm-down) {
    padding: 20px 30px 28px;
  }
}
