.wrapper {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0;
  }
}

.imgWrapper {
  width: 100%;
  height: 733px;
  position: relative;

  @media (--sm-down) {
    height: 400px;
  }

  .img {
    object-fit: cover;
  }
}

.titleWrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.title {
  line-height: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-top: 1px solid #000;
    position: absolute;
    bottom: -10px;
  }
}
