.wrapper {
  padding: 90px 0 45px;

  @media (--sm-down) {
    padding: 60px 0 30px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;

  @media (--sm-down) {
    margin: 0 -30px;
  }
}

.item {
  width: calc(25% - 1px);
  box-shadow: 0 0 0 1px var(--divider);

  @media (--lg-down) {
    width: calc(33.3333% - 1px);
  }

  @media (--md-down) {
    width: calc(50% - 1px);
  }

  @media (--sm-down) {
    width: calc(100% - 1px);
  }
}

.loaderWrapper {
  padding: 150px 0;
}

.noItems {
  text-align: center;
  color: var(--secondary-contrastText);
  padding: 150px 0;
}
