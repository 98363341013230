.wrap {
  display: grid;
  grid-template-columns: 31% 50%;
  gap: 40px;
  padding: 45px 0;

  @media (--md-down) {
    grid-template-columns: 100%;
    gap: 10px;
    padding: 30px 0;
  }
}

.accordion {
  margin-top: -27px;

  @media (--md-down) {
    margin-top: 0;
  }
}
