.wrap {
  padding: 90px 0 123px;
  background: var(--primary-main);
  color: var(--primary-contrastText);

  @media (--md-down) {
    padding: 5px 0 110px;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  padding: 24px;
  border: 1px solid var(--primary-light);
  height: 100%;
  display: flex;

  @media (--md-down) {
    display: block;
    padding: 20px 0;
    border: 0;
    border-bottom: 1px solid var(--primary-light);
  }

  &.filled {
    @media (--md-up) {
      background: var(--primary-light);
    }
  }

  .regular {
    font-weight: 400;
  }

  .secondaryColor {
    color: rgb(var(--primary-contrastText-rgb) / 50%);
  }

  .sup {
    font-size: 0.5em;
    vertical-align: super;
  }
}

.label {
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 10px;
  min-width: 120px;
  padding-top: 10px;

  @media (--md-down) {
    margin-bottom: 5px;
    padding: 0;
  }

  + * {
    width: 100%;
  }
}

.searchBtn {
  padding-top: 60px;
  text-align: center;
}

.shapeList {
  display: flex;
  flex-wrap: wrap;
}

.shapeBtn {
  padding: 6px 0;
  margin: 0 20px;
  position: relative;
  color: var(--primary-contrastText);
  font-family: var(--font-secondary);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid currentcolor;
    opacity: 0;
    transform: translateY(5px);
    transition: all ease 0.25s;
  }

  &.active {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .imgWrap {
    height: 60px;
    position: relative;
    margin-bottom: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
}
