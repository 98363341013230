.wrap {
  padding: 45px 0;

  @media (--md-down) {
    padding: 30px 0;
  }

  .slide {
    width: calc((100% / 3) - 10px);

    @media (--md-down) {
      width: 50%;
    }

    @media (--sm-down) {
      width: calc((100% / 2) - 10px);
    }
  }
}

.slideTitle {
  @media (--sm-down) {
    padding: 0 30px;
  }
}

.slideDescription {
  @media (--sm-down) {
    padding: 0 30px;
  }
}
