.htmlWrapper {
  width: 100%;
  overflow: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-primary);
    font-weight: 500;
  }

  h2 {
    font-size: 23px;
  }

  h3 {
    font-size: 20px;
  }

  h4,
  h5,
  h6 {
    font-size: 17px;
  }
}
