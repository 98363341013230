.wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (--sm-down) {
    display: block;
  }
}

.imgWrapper {
  height: 560px;
  position: relative;

  @media (--sm-down) {
    height: auto;
    padding-top: 100%;
  }

  img {
    object-fit: cover;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: var(--background-default);
  position: relative;

  @media (--sm-down) {
    padding: 20px;
  }
}

.logoImg {
  height: 60px;
  position: relative;

  img {
    object-fit: scale-down;
  }
}

.description {
  margin-top: 45px;

  @media (--sm-down) {
    margin-top: 25px;
  }
}

.btnWrapper {
  margin-top: auto;

  @media (--sm-down) {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
