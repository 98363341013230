.wrap {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0 60px;
  }
}

.contentWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (--md-down) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--sm-down) {
    grid-template-columns: 1fr;
  }
}
