.wrap {
  position: relative;
  padding-top: 50%;
  display: block;
  background-color: var(--primary-main);

  @media (--sm-down) {
    padding-top: 100%;
  }

  &.detailed {
    @media (--md-down) {
      padding: 0;
    }
  }

  img {
    object-fit: cover;
  }
}

.inner {
  position: absolute;
  inset: 0;
  padding: 24px 20px;

  .detailed & {
    @media (--md-down) {
      position: relative;
    }
  }
}

.name {
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  color: var(--primary-main);
}

.detailedWrap {
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: row-reverse;
  gap: 60px;

  @media (--sm-down) {
    padding: 20px;
    display: block;
    height: auto;
    text-align: center;
  }
}

.textBlock {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detailedName {
  font-size: 28px;
  line-height: 1.26;
  text-transform: uppercase;
  font-family: var(--font-secondary);
}

.detailedName,
.detailedDescription {
  color: var(--primary-contrastText);
  margin-bottom: 20px;
}

.detailedImg {
  position: relative;
  width: calc(60% - 68px);
  flex-shrink: 0;

  @media (--sm-down) {
    width: 100%;
    height: 200px;
  }

  img {
    object-fit: contain;
  }
}
