.title {
  margin: 0;
  padding-left: 10px;
  display: block;
  width: 100%;

  &:disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }

  .caption {
    font-size: 12px;
  }
}

.item {
  border: 1px solid var(--divider);

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.content {
  padding: 20px;
  border-top: 1px solid var(--divider);
}

.inputWrap {
  :global {
    .StripeElement {
      border: 1px solid var(--divider);
      padding: 0 20px;
      cursor: text;
      min-height: 46px;

      &.StripeElement--invalid {
        border-color: var(--error-main);
      }
    }
  }

  &.error {
    :global {
      .StripeElement {
        border-color: var(--error-main);
      }
    }
  }
}
