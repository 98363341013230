.wrapper {
  padding: 90px 0 245px;
  background-color: #3f384a;

  @media (--sm-down) {
    padding: 60px 0 230px;
  }
}

.tableWrapper {
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: end;

  &.notFound {
    display: block;

    .leftBar {
      display: none;
    }
  }

  @media (--lg-down) {
    grid-template-columns: 20% 80%;
  }

  @media (--sm-down) {
    margin: 0 -30px;
    grid-template-columns: 30% 70%;
  }
}

.leftBar {
  padding-bottom: 43px;

  @media (--sm-down) {
    padding-bottom: 25px;
  }
}

.rowTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--background-default);
  padding: 24px 40px;

  @media (--sm-down) {
    font-size: 14px;
    padding: 18px 20px;
    line-height: 20px;
  }

  &:nth-child(2n) {
    background-color: rgb(var(--background-default-rgb) / 10%);
  }
}

.sliderWrapper {
  width: 100%;
  margin-bottom: -42px;
}
