:global {
  .simplebar-scrollable-x {
    :local {
      .list {
        justify-content: flex-start;
      }
    }
  }
}

.list {
  display: flex;
  justify-content: center;
}

.item {
  padding: 20px 15px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.link {
  width: 190px;
  font-family: var(--font-secondary);
  text-align: center;
  display: block;
  font-size: 20px;
  color: var(--primary-main);

  @media (--md-down) {
    width: 180px;
    font-size: 16px;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .name {
      &::after {
        opacity: 1;
        bottom: -5px;
      }
    }
  }

  .imgWrap {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;

    img {
      transition: all ease 0.25s;
      object-fit: cover;
    }
  }

  .name {
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      height: 2px;
      background: currentcolor;
      opacity: 0;
      transition: all ease 0.25s;
    }
  }
}
