.contentWrapper {
  max-width: 855px;
  margin: 0 auto;
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0;
  }
}

.title {
  padding: 80px 0 45px;
  text-align: center;

  @media (--sm-down) {
    padding: 60px 0 30px;
  }
}
