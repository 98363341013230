.wrap {
  padding: 45px 0 45px 90px;

  @media (--md-down) {
    padding: 45px 0;
  }

  @media (--sm-down) {
    padding: 30px 0;
  }
}

.grid {
  @media (--md-up) {
    display: grid;
    grid-template-columns: 410px calc(100% - 410px);
    grid-template-rows: auto 100%;
  }
}

.titleWrap {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;
  padding: 210px 50px 0 0;

  @media (--md-up) {
    grid-area: 1 / 1 / 2 / 2;
  }

  @media (--md-down) {
    padding: 0 30px;
    text-align: center;
    justify-content: center;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 50px;
    color: var(--text-primary);
    max-width: 354px;

    @media (--md-down) {
      display: none;
    }
  }

  h2 {
    margin-bottom: 50px;

    @media (--sm-down) {
      margin-bottom: 30px;
    }
  }
}

.sliderWrap {
  @media (--md-up) {
    grid-area: 1 / 2 / 3 / 3;
  }

  .slide {
    width: 410px;

    @media (--sm-down) {
      width: 240px;
    }
  }
}

.controls {
  @media (--md-up) {
    grid-area: 2 / 1 / 3 / 2;
  }

  @media (--md-down) {
    padding-top: 30px;
    display: flex;
    justify-content: center;
  }
}
