.item {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 20px 0;
  gap: 40px;
  position: relative;

  &.withLink {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr 265px;

    @media (--lg-down) {
      grid-template-columns: 1fr auto;
    }
  }

  @media (--md-up) {
    align-items: center;
  }

  @media (--sm-down) {
    gap: 0;
  }
}

.body {
  flex-grow: 1;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 17px;

  &:not(.hasImg) {
    @media (--sm-down) {
      height: auto;
    }
  }

  @media (--md-down) {
    flex-wrap: wrap;
  }

  @media (--sm-down) {
    display: block;
    width: calc(100% - 120px);
    height: 120px;
  }
}

.media {
  width: 265px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 265px;
  position: relative;
  flex-shrink: 0;

  @media (--md-down) {
    width: 120px;
    height: 120px;
  }

  @media (--sm-down) {
    position: absolute;
    right: 0;
    top: 20px;
  }

  img {
    object-fit: cover;
  }
}

.rating {
  display: flex;
  align-items: center;

  @media (--md-down) {
    width: 100%;
  }

  .value {
    font-weight: 500;
    margin-right: 5px;
  }
}

.name {
  font-family: var(--font-secondary);
  font-size: 20px;
  margin-left: 30px;

  @media (--md-down) {
    margin-left: 0;
    font-size: 14px;
    margin-right: 15px;
  }
}

.date {
  @media (--md-up) {
    margin-left: 24px;
  }
}

.contentWrap {
  display: flex;
  justify-content: space-between;
}

.productName {
  font-weight: 500;
}

.linkWrapper {
  @media (--lg-down) {
    display: none;
  }

  .linkTitle {
    text-transform: uppercase;
    color: var(--secondary-contrastText);
  }

  .link {
    text-transform: uppercase;
    color: var(--text-primary);
  }
}
