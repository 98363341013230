.title {
  display: flex;

  .titleItem {
    @media (--sm-down) {
      font-size: 12px;
    }

    &:nth-child(2n) {
      color: var(--grey-500);
      margin-left: 24px;
    }

    &:nth-child(3n) {
      margin-left: 5px;
    }
  }
}

.description {
  color: var(--primary-main);
}
