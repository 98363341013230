.section {
  padding: 100px 0 150px;
  min-height: 500px;
  text-align: center;

  h2 {
    color: var(--primary-main);
    margin: 20px 0 10px;
  }
}

.btnWrap {
  width: 250px;
  margin: 50px auto 0;
}
