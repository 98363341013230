.contentWrap {
  padding: 90px 0 45px;

  @media (--md-down) {
    padding: 45px 0 30px;
  }

  h1 {
    max-width: 855px;
    margin: 0 auto;
    text-align: center;
  }
}

.date {
  text-align: center;
  color: var(--primary-main);
  margin-top: 9px;
}

.mainImg {
  max-width: 1150px;
  padding-top: 34.5%;
  margin: 39px auto 0;
  position: relative;

  @media (--lg-down) {
    padding-top: 50%;
  }

  @media (--sm-down) {
    margin: 39px -30px 0;
    padding-top: 100%;
  }

  img {
    object-fit: cover;
  }
}

.content {
  max-width: 1050px;
  margin: 0 auto;
}

.injectorWrap {
  margin-top: 63px;

  @media (--sm-down) {
    margin-top: 30px;
  }

  p:not(:has(img)) {
    max-width: 855px;
    margin: 0 auto;
  }

  p:has(img) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 63px 0;

    @media (--lg-down) {
      gap: 20px;
      margin: 40px -30px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    @media (--sm-down) {
      margin: 30px 0;
    }
  }
}
