.price {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: var(--primary-main);

  @media (--md-down) {
    justify-content: center;
    font-size: 24px;
  }

  .oldPrice {
    opacity: 0.3;
    font-size: 20px;
    margin-left: 10px;
    text-decoration: line-through;
  }
}
