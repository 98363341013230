.wrapper {
  padding-bottom: 90px;

  @media (--sm-down) {
    padding-bottom: 45px;
  }

  h1 {
    text-align: center;
    margin: 50px 0;
  }
}

.injectorWrap {
  img {
    margin: 0 auto;

    @media (--md-down) {
      width: 100%;
      height: auto;
    }
  }
}
