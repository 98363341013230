.bannerContent {
  max-width: 100%;
}

.appointmentsWrapper {
  padding: 45px 0;

  @media (--md-down) {
    padding: 35px 0;
  }
}

.categoryBannerWrapper {
  padding-bottom: 45px;

  @media (--sm-down) {
    padding-bottom: 30px;
  }
}
