.wrapper {
  padding: 45px 0;

  @media (--sm-down) {
    padding: 30px 0;
  }
}

.gallery {
  display: flex;
  justify-content: center;
  gap: 30px;

  @media (--md-down) {
    flex-direction: column;
  }
}

.item {
  width: calc(100% / 3);

  @media (--md-down) {
    width: 100%;
  }
}

.imgWrapper {
  position: relative;
  height: 722px;

  @media (--md-down) {
    height: 400px;
  }

  .img {
    object-fit: cover;
  }
}

.titleWrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.title {
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-top: 1px solid #000;
    position: absolute;
    bottom: -10px;
  }
}
