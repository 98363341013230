.wrapper {
  padding: 45px 0;
  margin-left: 170px;

  @media (--sm-down) {
    padding: 30px 0;
  }

  @media (--lg-down) {
    margin-left: 0;
  }
}

.collage {
  display: flex;
  justify-content: center;

  @media (--lg-down) {
    flex-direction: column;
  }
}

.largeImg {
  width: 718px;
  height: 1000px;
  margin-bottom: 110px;
  position: relative;

  @media (--md-down) {
    width: 100%;
    height: 800px;
  }

  @media (--lg-down) {
    margin-bottom: 50px;
  }

  .img {
    object-fit: cover;
  }
}

.smallImg {
  margin-left: -85px;
  align-self: flex-end;
  width: 334px;
  height: 500px;
  position: relative;

  @media (--lg-down) {
    margin-top: -40%;
  }

  @media (--sm-down) {
    width: 100%;
  }

  .img {
    object-fit: cover;
  }
}

.textBlockWrapper {
  margin: 250px 0 0 30px;

  @media (--lg-down) {
    margin: 30px 0 0;
  }

  h2 {
    max-width: 295px;
    font-size: 48px;
  }
}

.titleWrap {
  display: flex;
  gap: 20px;
}

.description {
  white-space: break-spaces;
  max-width: 356px;
  margin-top: 34px;
}

.imgTitle {
  display: flex;
  max-height: 60px;
  max-width: 268px;
  position: relative;

  img {
    object-fit: contain;
  }
}

.linkWrap {
  margin-top: 25px;
}

.link {
  font-weight: 500;
  color: var(--text-primary);
  text-transform: uppercase;
  padding-right: 15px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-bottom: 1px solid var(--text-primary);
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}
