.wrap {
  display: flex;
  padding: 80px 0 110px;
  position: relative;

  @media (--md-down) {
    flex-direction: column-reverse;
    padding-bottom: 150px;
  }

  h2 {
    color: var(--primary-main);
    margin-bottom: 20px;
  }

  h4 {
    font-family: var(--font-primary);
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}

.section {
  margin-bottom: 40px;
}

.forms {
  @media (--md-up) {
    padding-right: 70px;
  }
}

.cart {
  padding-left: 76px;
  width: 500px;
  flex-shrink: 0;

  @media (--md-up) {
    background: var(--background-secondary);
    box-shadow: 1000px 0 0 1000px var(--background-secondary);
  }

  @media (--lg-down) {
    width: 350px;
    padding-left: 30px;
  }

  @media (--md-down) {
    width: 100%;
    padding-left: 0;
  }

  .title {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px;
  font-size: 14px;
  color: var(--text-secondary);

  &::before,
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--divider);
    flex-grow: 1;
  }

  span {
    padding: 0 30px;
  }
}

.totalPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 15px 0;

  @media (--md-down) {
    position: absolute;
    bottom: 120px;
    left: 0;
    right: 0;
  }

  b {
    font-weight: 500;
  }
}

.submit {
  @media (--md-down) {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
  }
}
