.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.noContent {
  position: relative;
}

.title {
  color: var(--primary-contrastText);
}

.navWrapper {
  color: var(--primary-contrastText);

  svg {
    color: var(--primary-contrastText);
  }
}

.sliderWrap {
  width: 100%;
  margin-bottom: 25px;

  .slider {
    background-color: var(--background-default);

    :global {
      .swiper-wrapper {
        .swiper-slide {
          height: auto;
        }
      }
    }
  }
}

.nav {
  margin-top: auto;
}
