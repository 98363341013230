.wrap {
  padding-top: 45px;
}

.item {
  position: relative;

  &::after {
    margin-top: -1px;
    content: '';
    display: block;
    inset: 0;
    background-color: #332d3b;
    position: absolute;
    translate: 0 -50%;
    z-index: -1;
  }
}
